<template>
    <div class="container-bg" @click="closeModal">
        <div class="container">
            <div class='btn-grey-small cross' @click="closeModal">
                <img width='14' src='../../../public/assets/images/close_small.svg' />
            </div>

            <div class="creator-content">
                <div class="top">
                    <div class="img"></div>
                    <div class="creator-right">
                        <div class="top-right">
                            <div class="name">Zabala</div>
                            <div class="rs"><img width='15' src='../../../public/assets/images/insta.svg' /> zabala.mat</div>
                        </div>
                        <div class="bottom-right">
                            <div class="date">Launched<br />in March 2025</div>
                            <div class="text">
                                Recently graduated from Central Saint Martins, Mathew Zabala is a visionary designer whose work bridges fashion and innovation. Best known for crafting Lil Yachty's iconic eyewear for his European tour, Zabala's designs—futuristic and meticulously crafted—have captured attention at events like Paris Fashion Week. 
                            </div>
                            <div class="text">
                                Drawing inspiration from motorsport and high fashion, Zabala continues to push boundaries with his debut collection, blending bold menswear with standout accessories like the Z-Frame, a testament to his passion for timeless and unique design.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="bottom-top">
                        <div class="designer-name">Zabala's Designs</div>
                        <div class="arrows">
                            <div class='btn-grey-small'>
                                <img width='14' src='../../../public/assets/images/arrow_left.svg' />
                            </div>
                            <div class='btn-grey-small'>
                                <img width='14' src='../../../public/assets/images/arrow_right.svg' />
                            </div>
                        </div>
                    </div>
                    <div class="products-list">
                        <div class="product">
                            <div class="img-product">
                                <img src='../../../public/assets/images/zframe.png' />
                            </div>
                            <div class="product-bottom">
                                <div class="name">Z-Frame</div>
                                <div>/</div>
                                <div class="price">
                                    280€<br />
                                    <span class="grey">Sur mesure disponible</span>
                                </div>
                            </div>
                        </div>
                        <div class="product">
                            <div class="img-product">
                                <img src='../../../public/assets/images/zframe.png' />
                            </div>
                            <div class="product-bottom">
                                <div class="name">Z-Frame</div>
                                <div>/</div>
                                <div class="price">
                                    280€<br />
                                    <span class="grey">Sur mesure disponible</span>
                                </div>
                            </div>
                        </div>
                        <div class="product">
                            <div class="img-product">
                                <img src='../../../public/assets/images/zframe.png' />
                            </div>
                            <div class="product-bottom">
                                <div class="name">Z-Frame</div>
                                <div>/</div>
                                <div class="price">
                                    280€<br />
                                    <span class="grey">Sur mesure disponible</span>
                                </div>
                            </div>
                        </div>
                        <div class="product">
                            <div class="img-product">
                                <img src='../../../public/assets/images/zframe.png' />
                            </div>
                            <div class="product-bottom">
                                <div class="name">Z-Frame</div>
                                <div>/</div>
                                <div class="price">
                                    280€<br />
                                    <span class="grey">Sur mesure disponible</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
</template>

<script>
export default {
    name: "CreatorOverlay",
    props:[],
    methods: {
        closeModal($event)
        {
            
            if (jQuery($event.target).parents('.container').length) {
                if(jQuery($event.target).hasClass('cross') || jQuery($event.target).parents('.cross').length)
                {
                    this.$emit('closeCreatorOverlay')
                }
            }
            else
            {
              this.$emit('closeCreatorOverlay')
            }
        },
    }
}
</script>

<style scoped>
 .rs
    {
        font-size:14px;
    }

@media screen and (min-width: 768px) and (max-width: 1300px) {
    .designer-name
    {
        font-size: 20px !important;
    }
    .creator-right .bottom-right,
    .rs
    {
        font-size:14px !important;
    }
.creator-right .name
{
    font-size:35px !important;
}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .cross
    {
   
    }
    .container
    {
        padding:20px !important;
        
        height:calc(100% - 20px) !important;
        right:10px !important;
        overflow: auto;
    }
    .creator-content .bottom 
    {
        margin-top:20px;
        padding-bottom:20px;
    }
    .creator-content
    {
        display: block !important;
    }
    .creator-content .top
    {
        flex-direction: column;
    }
    .creator-right .bottom-right
    {
        display:block !important;
    }
    .creator-right .bottom-right .text
    {
        width: 60% !important;
        padding-left: 0 !important;
        position: relative;
        left:40%;
       
    }
    .creator-right .bottom-right
    {
        font-size: 13px !important;
    }
    .product-bottom .name
    {
        font-size: 13px !important;
    }
    .product-bottom
    {
        font-size: 13px !important;
        align-items: center !important;
    }
    .products-list .product
    {
        flex: 0 0 auto;
        width:35vw !important;
    }
    .products-list
    {
        display: flex;
  overflow-x: auto; /* Active le défilement horizontal */
  white-space: nowrap; /* Évite le retour à la ligne */
  gap: 10px; /* Espacement entre les éléments */
    }
    .price .grey
    {
        display: none !important;
    }
    .name
    {
        font-size: 35px !important;
        margin-left: 0 !important;
        position: absolute !important;
        left:calc(30vw + 20px) !important;
        top:-31px !important;
    }
    .creator-content .top 
    {
        height:fit-content !important;
    }
    .btn-grey-small
    {
        width:36px !important;
        height:36px !important;
    }
    .designer-name
    {
        font-size:16px !important;
    }
    .creator-right .bottom-right .date
    {
        width: 40% !important;
        padding-left: 0 !important;
        position: relative;
        top:32px;        
    }
    .creator-right .top-right
    {
        padding-top:10px;
        position: relative;
        
    }
    
    .creator-content .img
    {
        width:30vw !important;
        height:30vw !important;
    }
    .rs
    {
        font-size: 13px;
        position: absolute !important;
        left:30vw !important;
        top:-30vw !important;
    }
    .creator-right
    {
        width: 100% !important;
    }
}
@media screen and (max-width: 767px) {
    .rs
    {
        font-size:12px !important;
    }

    .cross
    {
        right:10px !important;
        top:10px !important;
    }
    .container
    {
        padding:10px !important;
        width:calc(100% - 20px) !important;
        height:calc(100% - 20px) !important;
        right:10px !important;
        overflow: auto;
    }
    .creator-content .bottom 
    {
        margin-top:20px;
        padding-bottom:20px;
    }
    .creator-content
    {
        display: block !important;
    }
    .creator-content .top
    {
        flex-direction: column;
    }
    .creator-right .bottom-right
    {
        display:block !important;
    }
    .creator-right .bottom-right .text
    {
        width: 60% !important;
        padding-left: 0 !important;
        position: relative;
        left:40%;
       
    }
    .creator-right .bottom-right
    {
        font-size: 13px !important;
    }
    .product-bottom .name
    {
        font-size: 13px !important;
    }
    .product-bottom
    {
        font-size: 13px !important;
        align-items: center !important;
    }
    .products-list .product
    {
        flex: 0 0 auto;
        width:60vw !important;
    }
    .products-list
    {
        display: flex;
  overflow-x: auto; /* Active le défilement horizontal */
  white-space: nowrap; /* Évite le retour à la ligne */
  gap: 10px; /* Espacement entre les éléments */
    }
    .price .grey
    {
        display: none !important;
    }
    .name
    {
        font-size: 35px !important;
        margin-left: 0 !important;
    }
    .creator-content .top 
    {
        height:fit-content !important;
    }
    .btn-grey-small
    {
        width:36px !important;
        height:36px !important;
    }
    .designer-name
    {
        font-size:16px !important;
    }
    .creator-right .bottom-right .date
    {
        width: 40% !important;
        padding-left: 0 !important;
        position: relative;
        top:32px;        
    }
    .bottom-top
    {
        padding-left: 14px;  
    }
    .creator-right .top-right
    {
        padding-top:10px;
        padding-left: 14px;
        position: relative;
    }
    .creator-right .bottom-right
    {
      
        padding-left: 14px;
      
    }
    .creator-content .img
    {
        width:50vw !important;
        height:50vw !important;
    }
    .rs
    {
        font-size: 13px;
        position: absolute !important;
        left:50vw !important;
        top:-16px !important;
    }
    .creator-right
    {
        width: 100% !important;
    }
    
}
.grey
{
    color:#999999;
}
.product-bottom .name
{
    margin-left:0;
    font-size: 14px;
}
.product-bottom
{
    margin-top:15px;
    font-size: 14px;
}
.designer-name
{
 font-size:22px;   
}
.bottom-top .arrows div:first-child
{
    margin-right:10px;
}
.bottom-top .arrows 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bottom-top
{
    display: flex;
    padding-left: 14px;
    justify-content: space-between;
    align-items: center;
}
.rs img
{
    margin-right:10px;
}
.rs 
{
    margin-left:20px;
    display: flex;
    align-items: center;
    position: relative;
    top:-5px;
}
.name 
{
    font-size:52px;
    margin-left:20px;
    line-height: 1;
}
.product-bottom
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.products-list .product .img-product
{
    background-color: #F1F1F1;
    padding-top:30%;
    padding-bottom:30%;
}
.products-list .product
{
    width:24%;
}
.products-list
{
    margin-top:10px;
    display: flex;
    justify-content: space-between;
}
.creator-right .top-right
{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  
}
.creator-right .bottom-right .date 
{
    padding-left:20px;
    width:25%;
    color:#999999;
}
.creator-right .bottom-right .text
{
    padding-left:20px;
    width:35%;
}
.creator-right .bottom-right
{
    font-size:16px;
    display: flex;
    justify-content: space-between;
    padding-right:5%;
}
.creator-right
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
}
.creator-content
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

}
.creator-content .top
{
    display: flex;
    justify-content: space-between;
    height: 50%;
}
.creator-content .img 
{
    width:30%;
    border-radius: 5px;
    background-size: cover;
    background-position: center center;
    background-image:url('../../../public/assets/images/zabala.jpg');
}
.cross 
{
    position: absolute;
    right: 20px;
    top:20px;
}
.btn-grey-small
{
    background:#F1F1F1;
    display: flex;
    cursor: pointer;
    width:40px;
    height:40px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.actions .btn {
    margin-left:10px;
    margin-right:10px;
}
.container-bg {
    position: fixed;
    top:0;
    left:0;
    width:100%;
    z-index: 10000;
    height:100%;
    background:rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(20px);
}
.container{
    background-color: white;
    width: 75%;
    height:calc(100% - 40px);
    padding: 20px;
    border-radius: 5px;
    font-size: 1rem;
    
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 10000;
}





</style>

export default {
    obj: [
        {
            link: 'color',
            txt: 'Couleur',
            txt_en: 'Color',
        },
        {
            link: 'texture',
            txt: 'Texture',
            txt_en: 'Texture',
        },
        {
            link: 'branch',
            txt: 'Branches',
            txt_en: 'Temple',
        },
        {
            link: 'tint',
            txt: 'Verres',
            txt_en: 'Lens',
        },
        {
            link: 'gravure',
            txt: 'Gravure',
            txt_en: 'Engraving',
            price: '(+10€)',
        },
    ]
}
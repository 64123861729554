<template>
    <div id="popup-login" @click="closeModal($event)" class="popup-login">
			<div class="content">
				
        <div class='btn-grey-small cross' @click="closeShare()">
          <img width='14'
          src='../../../public/assets/images/close_small.svg' />
      </div>
        <div class="content-share">
        
        <div class="img-content-block">
          <div class="img-content">
                                  
              <img v-for="(layer,indexlayer) in photo" :key="indexlayer" :style="'opacity:'+getOpacityElement(indexlayer)+';'" :src="calculatePhoto(layer)" :class="{img:true,'render-layer2':indexlayer>0}"  />

          </div>

        </div>
        <div class="right">
           <div class="title">Partagez votre modèle</div>
            <br />
             {{glass.name}}<br />
             {{glass.brand_name}}<br />/ Custom
           
          
				</div>
        </div>
        <button class="btn-copy" @click="copyLink()">
            <span v-if="copied==false">Copier le lien</span>
            <span v-else>Copié !</span>
            <img width='14'
            src='../../../public/assets/images/content_copy.svg' />
        </button>

			</div>
		</div>
</template>


<script>
import {Auth} from "@simb2s/senseye-sdk";
import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'

export default {
  components: {  },
  name: 'PopupShare',
  data() {
    return {
      copied:false
    }
  },
  async mounted(){

  },
  props:['glass','favorite','photo',"urlPhotoSlider"],
  methods:{
    calculatePhoto(layer_info) {

    let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
    let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
    let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100


    let url
    if(brightness==100 && hue==100 && saturation==100)
    {
    url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
    }
    else {
    url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
    }



    return url

    },
    getBrightnessElement(layer) {
            
            if(this.photo[layer].presets.brightness != null) {
                return this.photo[layer].presets.brightness/100
            }
            else {
                return 1
            }
        },
        getHueElement(layer) {

            if(this.photo[layer].presets.hue != null) {
                return this.photo[layer].presets.hue/1.7
            }
            else {
                return 0
            }
           
        },
        getSaturationElement(layer) {
            if(this.photo[layer].presets.saturation != null) {
                return this.photo[layer].presets.saturation/100
            }
            else {
                return 1
            }
          
        },
        getOpacityElement(layer) {
            if(this.photo[layer].presets.opacity != null) {
                return this.photo[layer].presets.opacity/100
            }
            else {
                return 1
            }
           
        },
    closeShare() {
      emitter.emit('close-share')
    },
    closeModal($event)
    {
        if (jQuery($event.target).parents('.content').length) {

        }
        else
        {
          if (jQuery($event.target).parents('.right').length) {

          }
          else
          {
            if (jQuery($event.target).hasClass('.btn-copy')) {

            }
            else
            {
              emitter.emit('close-share')
             }
           
          }
           
        }
    },
    copyLink(){
       navigator.clipboard.writeText('https://visages.com/collection/'+this.glass.id+'/'+this.favorite.id);
       this.copied=true
       setTimeout(function(){
         this.copied=true
       },3000)
    }
    
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-grey-small
{

  z-index:99;
    background:#F1F1F1;
    display: flex;
    cursor: pointer;
    width:40px;
    height:40px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}
.cross 
{
  position: absolute;
  right:20px;
  top:20px;
}
.btn-copy
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:20px;
  text-align: left !important;
  background-color: #63FF2B !important;
  border:1px solid #63FF2B !important;
  color:#000 !important;
}
.content-share .img-content-block
{
  background:#F1F1F1;
  border-radius: 5px;
  padding-top:20px;
  padding-bottom:20px;
width:50%;
position: relative;
}
.content-share
{
  display: flex;
}
.popup-login
        {
            animation: fadeanimation 0.4s ease;
        }
#popup-login .content .right .title
{
  font-size:35px;
  text-align: left;
  font-weight: 400;
  line-height: 1;
}
#cross
{
  position: absolute;
   
    cursor: pointer;
    border-radius: 50%;
    padding: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}

#popup-login .content
{
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

#popup-login .content .right
{
  font-size:16px;
  text-align: left;
  width:55%;
}
#popup-login .content  .img.render-layer2
{
  position:absolute;
  left:0;
  top:50%;
  transform:translateY(-50%);
  width:100%;
  height:auto;
}
#popup-login .content .img-content
{
  position:relative;
  left:0;
  top:0;
  overflow: hidden;
  width:100%;
  height:100%;
}
#popup-login .content
{
  max-width: 618px;
  padding:20px;
}
#popup-login .content .left
{
	position:absolute;
	left:0;
	top:0;
	height:100%;
  background-size:cover;
  background-position: center center;
	display:inline-block;
	vertical-align: top;
	width:45%;
	float:left;
}
.share-links a img{
  display: inline-block;
}
.share-links a 
{
  display:inline-block;
  vertical-align: top;
  margin-left:10px;
  margin-right:10px;
}
#popup-login .content .right
{
  padding-left:5%;
  padding-right:5%;
}

@media screen and (max-width:760px)
{
  #popup-login .content .left 
  {
    overflow:hidden;
  }
  #popup-login .content
  {
    padding:10px !important;
  }
  #popup-login .content .right
  {
    font-size:13px !important;
  }
  .cross 
  {
    right:10px !important;
    top:10px !important;
  }
}
@media screen and (min-width:760px) and (max-width:1300px)
{
.btn-copy
{
  font-size:14px !important;
  padding:11px 14px !important;
}
#popup-login .content .right
  {
    font-size:14px !important;
  }
}
</style>
